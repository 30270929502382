<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        a) When the species are ordered from largest on the left to smallest on the right, which
        list is correct?
      </p>

      <v-radio-group
        v-model="inputs.multipleChoiceAnswer"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">b) I think this because:</p>

      <p class="mb-n6">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'CalPolyPracticeExam3Q3',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {text: `$\\ce{Cl^{-} > P^{3-} > Al^{3+} > Si > Na^{+}}$`, value: 'a'},
        {text: `$\\ce{P^{3-} > Cl^{-} > Al^{3+} > Na^{+} > Si}$`, value: 'b'},
        {text: `$\\ce{P^{3-} > Cl^{-} > Si > Na^{+} > Al^{3+}}$`, value: 'c'},
        {text: `$\\ce{Al^{3+} > Na^{+} > P^{3-} > Cl^{-} > Si}$`, value: 'd'},
        {text: `$\\ce{Cl^{-} > P^{3-} > Na^{+} > Al^{3+} > Si}$`, value: 'e'},
      ],
    };
  },
};
</script>
